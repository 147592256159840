<template>
  <v-scale-transition>
    <kits-panel
      :kits-items="dmtReports"
      :breadcrumbs="breadcrumbs"
    >
      <h3 class="headline grey--text text-xs-center hidden-sm-and-down">
        DMT Reports
      </h3>
    </kits-panel>
  </v-scale-transition>
</template>

<script>
const title = 'DMT Reports'

export default {
  components: {
    KitsPanel: () => import(/* webpackChunkName: "kitsPanel" */ '@/components/KitsPanel'),
  },
  data () {
    return {
      breadcrumbs: [
        {
          text: 'DMT Kits', disabled: false, to: '/support/dmt_kits',
        },
        {
          text: title, disabled: true,
        },
      ],
    }
  },
  computed: {
    dmtReports: function () {
      const menus = [
        {
          icon: 'mdi-account-clock',
          path: '/support/md_periodic_performance',
          title: 'MD Performance',
          desc: 'Master Dealer periodic performance.',
        },
        {
          icon: 'mdi-account-clock-outline',
          path: '/support/ad_periodic_performance',
          title: 'AD Performance',
          desc: 'Area Dealer periodic performance.',
        },
        {
          icon: 'mdi-trophy',
          path: '/support/daily_activation_ranking',
          title: 'Daily Activation Ranking',
          desc: 'Top ranked dealers.',
        },
        {
          icon: 'mdi-car-multiple',
          path: '/support/car_bonanza',
          title: 'Car Bonanza',
          desc: 'Car Bonanza campaign.',
        },
        {
          icon: 'mdi-list-box-outline',
          path: '/support/user_registration',
          title: 'User Registration',
          desc: 'User Registration List.',
        },
        {
          icon: 'mdi-list-box-outline',
          path: '/support/dealer_registration',
          title: 'Dealer Registration Monthly',
          desc: 'Dealer Registration List.',
        },
        {
          icon: 'mdi-list-box-outline',
          path: '/support/dealer_registration_by_day',
          title: 'Dealer Registration Daily',
          desc: 'Dealer Registration List.',
        },
        {
          icon: 'mdi-list-box-outline',
          path: '/support/active_record',
          title: 'Active Record',
          desc: 'Active Record.',
        },
      ]

      return menus
    },
  },
}
</script>
